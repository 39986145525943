import deMessages from "../translations/de.json";
import appLocaleData from "react-intl/locale-data/de";

const DeLang = {
  messages: {
    ...deMessages
  },
  languageId: "german",
  locale: "de-DE",
  data: appLocaleData
};
export default DeLang;
