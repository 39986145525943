import React from "react";
import Header from "components/Header";
import MainSidebar from "../MainSidebar/MainSidebar";
import styles from "./styles.module.scss";

const LoggedInPage = ({Component}) => {
  return (
    <>
      <MainSidebar />
      <div className={styles.content}>
        <Header />
        <Component />
      </div>
    </>
  )
}
export default LoggedInPage;