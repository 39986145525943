import React, { Component, ReactNode } from "react";
import { Snackbar } from "@material-ui/core";
import styles from "./styles.module.scss";
import ErrorIcon from "assets/icons2/Exclamation.svg";
import SuccessIcon from "assets/icons2/SucessIcon.svg";
import Close from "assets/icons2/Close.svg";

interface SnackBarViewProps {
  vertical?: any;
  horizontal?: any;
  openSnackBar?: boolean;
  closeSnackBar(): void;
  type?: "success" | "error";
  message: string | ReactNode;
  closeIcon?: boolean;
  snackBarKey?: string;
  autoHideDuration?: number;
}

export default class SnackBarView extends Component<SnackBarViewProps, any> {
  renderMessageType() {
    const { type } = this.props;
    switch (type) {
      case "error":
        return <img src={ErrorIcon} className={styles.messageIcon} />;
      case "success":
        return <img src={SuccessIcon} className={styles.messageIcon} />;
    }
  }
  shouldComponentUpdate(nextProps) {
    return (
      nextProps.snackBarKey !== this.props.snackBarKey ||
      nextProps.message !== this.props.message
    );
  }

  render() {
    const {
      openSnackBar,
      vertical,
      horizontal,
      closeSnackBar,
      message,
      type,
      closeIcon,
      snackBarKey,
      autoHideDuration
    } = this.props;
    return (
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        key={snackBarKey}
        open={openSnackBar}
        autoHideDuration={autoHideDuration}
        onClose={closeSnackBar}
        ContentProps={{
          "aria-describedby": "message-id",
          classes: {
            root: `${styles.snackBarContent} ${type === "error" &&
              styles.errorSnackBar} ${type === "success" &&
              styles.successSnackBar}`
          }
        }}
        message={
          <div className={styles.messageWrap}>
            {this.renderMessageType()}
            <span className={styles.messageText}>{message}</span>
          </div>
        }
        action={[
          closeIcon ? (
            <img
              key={`img${snackBarKey}`}
              src={Close}
              onClick={closeSnackBar}
              className={styles.closeImg}
            />
          ) : null
        ]}
      ></Snackbar>
    );
  }
}
