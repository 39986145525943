import { PatientsActions } from "./actions";
import { Action, handleActions } from "redux-actions";
import { AuthActions } from "pages/Auth/redux/actions";
import { Detail } from "../interfaces";

interface PatientsState {
  patients: any[];
  details: any[];
  errorMessage: string | null;
  isLoading: boolean;
  patientsLoaded: boolean;
}

export const INITIAL_STATE: PatientsState = {
  patients: [],
  details: [],
  patientsLoaded: false,
  errorMessage: null,
  isLoading: false
};

const reducerMap = {};

reducerMap[PatientsActions.LOAD_PATIENTS_REQUEST] = (
  state: PatientsState,
  action: Action<void>
): PatientsState => {
  return {
    ...state,
    errorMessage: null,
    isLoading: true,
    patientsLoaded: false
  };
};

reducerMap[PatientsActions.LOAD_PATIENTS_SUCCESS] = (
  state: PatientsState,
  action: Action<any[]>
): PatientsState => {
  return {
    ...state,
    patients: action.payload,
    errorMessage: null,
    isLoading: false,
    patientsLoaded: true
  };
};

reducerMap[PatientsActions.LOAD_PATIENTS_FAILURE] = (
  state: PatientsState,
  action: Action<string>
): PatientsState => {
  return {
    ...state,
    errorMessage: action.payload,
    isLoading: false,
    patientsLoaded: false
  };
};

reducerMap[PatientsActions.LOAD_DETAILS_REQUEST] = (
  state: PatientsState,
  action: Action<void>
): PatientsState => {
  return { ...state, errorMessage: null, isLoading: true, details: [] };
};

reducerMap[PatientsActions.LOAD_DETAILS_SUCCESS] = (
  state: PatientsState,
  action: Action<{ details: Detail[]; page: string }>
): PatientsState => {
  return {
    ...state,
    details: {
      ...state.details,
      [action.payload.page]: action.payload.details
    },
    errorMessage: null,
    isLoading: false
  };
};

reducerMap[PatientsActions.LOAD_DETAILS_FAILURE] = (
  state: PatientsState,
  action: Action<string>
): PatientsState => {
  return {
    ...state,
    errorMessage: action.payload,
    isLoading: false,
    details: []
  };
};

reducerMap[AuthActions.LOGOUT_SUCCESS] = (
  state: PatientsState,
  action: Action<void>
): PatientsState => {
  return INITIAL_STATE;
};

export default handleActions<PatientsState, any>(reducerMap, INITIAL_STATE);
