import { StatusMessage, StatusMessageActions } from "./actions";
import { Action, handleActions } from "redux-actions";

interface StatusMessageState {
  statusMessage: StatusMessage | null;
}

export const INITIAL_STATE: StatusMessageState = {
  statusMessage: null
};

const reducerMap = {};

reducerMap[StatusMessageActions.PUT_MESSAGE] = (
  state: StatusMessageState,
  action: Action<StatusMessage>
): StatusMessageState => {
  return { statusMessage: action.payload };
};

reducerMap[StatusMessageActions.RESET] = (
  state: StatusMessageState
): StatusMessageState => {
  return { statusMessage: null };
};

export default handleActions<StatusMessageState, any>(
  reducerMap,
  INITIAL_STATE
);
