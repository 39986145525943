import React, { ReactElement } from "react";
import buttonStyles from "styles/buttons.module.scss";
import cn from "classnames";

interface Props {
  text: string;
  size?: "small" | "medium" | "large";
  startIcon?: ReactElement;
  endIcon?: ReactElement;
  disabled?: boolean;
  fullWidth: boolean;
  onClick?: (e?: any) => void;
  dataTestid?: string;
  className?: string;
  secondary?: boolean;
  id?: string;
}

class MaterialButton extends React.Component<Props> {
  static defaultProps = {
    size: "medium",
    fullWidth: false,
    disabled: false
  };

  render() {
    const {
      text,
      size,
      startIcon,
      disabled,
      onClick,
      fullWidth,
      dataTestid,
      endIcon,
      secondary,
      className,
      id
    } = this.props;

    return (
      <button
        data-testid={dataTestid}
        disabled={disabled}
        id={id}
        onClick={onClick}
        className={cn(buttonStyles.btn_default, className, {
          [buttonStyles.btn_secondary]: secondary,
          [buttonStyles.btn_large_default]: true,
          [buttonStyles.fullWidth]: fullWidth,
          [buttonStyles.btn_medium_default]: size === "medium",
          [buttonStyles.btn_small_default]: size === "small"
        })}
      >
        {startIcon && (
          <span className={buttonStyles.leftIconContainer}>{startIcon}</span>
        )}
        {text}

        {endIcon && (
          <span className={buttonStyles.rightIconContainer}>{endIcon}</span>
        )}
      </button>
    );
  }
}

export default MaterialButton;
