import Enlang from "./entries/en-US";
import Delang from "./entries/de-DE";
import { addLocaleData } from "react-intl";
import config from "./config";
import * as _ from "lodash";

export type SupportedLanguage = "english" | "german";

const AppLocale: any = {
  en: Enlang,
  de: Delang
};

addLocaleData(AppLocale.en.data);
addLocaleData(AppLocale.de.data);

export function getCurrentAppLocale(lang: SupportedLanguage) {
  const selectedLanguage = _.find(config.options, { languageId: lang });
  return AppLocale[selectedLanguage.locale];
}

export default AppLocale;
