import * as React from "react";
import { connect } from "react-redux";
import { IntlProvider } from "react-intl";
import { ThemeProvider as MuiThemeProvider } from "@material-ui/core/styles";
import pbTheme from "themes";
import * as _ from "lodash";
import { getCurrentAppLocale, SupportedLanguage } from "lang";
import Router from "./Router";
import styles from "./styles.module.scss";
import StatusMessageContainer from "../StatusMessage/StatusMessage.container";
import LoadingOverlap from "../LoadingOverlap";

export interface AppProps {
  language: SupportedLanguage;
  isLoggedIn: boolean;
  isLogoutLoading: boolean;
}

class App extends React.Component<AppProps, any> {
  render() {
    const { isLogoutLoading } = this.props;
    const currentAppLocale = getCurrentAppLocale(this.props.language);

    return (
      <IntlProvider
        locale={currentAppLocale.locale}
        messages={currentAppLocale.translations}
      >
        <MuiThemeProvider theme={pbTheme}>
          <div className={styles.app}>
            <Router />
          </div>
          {isLogoutLoading && <LoadingOverlap dimmed={true} />}
          <StatusMessageContainer />
        </MuiThemeProvider>
      </IntlProvider>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {
    language: _.get(state, "app.language"),
    isLoggedIn: !!_.get(state, "auth.session.access_token"),
    isLogoutLoading: _.get(state, "auth.isLogoutLoading")
  };
};

export default connect(mapStateToProps)(App);
