import React from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as _ from "lodash";
import { RouteComponentProps } from "react-router";
import UserBar from "components/MainSidebar/UserBar/UserBar";
import MenuItem from "components/MainSidebar/MenuItem/MenuItem";
import { ROUTES } from "businessLogic/routes.constants";
import styles from "./MainSidebar.module.scss";

interface Props extends RouteComponentProps {
  session: any;
  userDetails: any;
}

export var userName = "";

const getUserName = (userData) => {
  userName = userData.username;
  return userData?.firstName && userData?.lastName
    ? userData.firstName + " " + userData.lastName
    : userData.username
    ? userData.username
    : userData.login_identity;
};

const MainSidebar: React.FunctionComponent<{} & Props> = ({
  match,
  session,
  userDetails,
}) => {
  return (
    <div className={styles.root}>
      <div className={styles.sidebarHead}>
        <div className={styles.userBarContainer}>
          {session && userDetails && (
            <UserBar
              email={userDetails.username}
              name={userDetails && getUserName(userDetails)}
            />
          )}
        </div>
        <div>
          <MenuItem
            text={"Groups"}
            selected={match.path === ROUTES.RULE_ENGINE_GROUPS}
            to={ROUTES.RULE_ENGINE_GROUPS}
          />
          <MenuItem
            text={"Rules"}
            selected={match.path === ROUTES.RULE_ENGINE_RULES}
            to={ROUTES.RULE_ENGINE_RULES}
          />
          <MenuItem text={""} />
          <hr></hr>
          <MenuItem
            text={"Patient Dashboard"}
            selected={match.path === ROUTES.UPLOAD}
            to={ROUTES.UPLOAD}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state: any) => {
  return {
    session: _.get(state, "auth.session"),
    userDetails: _.get(state, "auth.userDetails"),
  };
};

export default connect(mapStateToProps, {})(withRouter(MainSidebar));
