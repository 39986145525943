import { createTheme } from "@material-ui/core/styles";
import colors from "styles/variables.colors.module.scss";

export default createTheme({
  palette: {
    primary: {
      main: colors.primaryMain,
      dark: colors.primaryDark,
      light: colors.primaryLight
    },
    secondary: {
      main: colors.secondaryMain
    }
  },
  overrides: {
    MuiInput: {
      root: {
        maxWidth: "420px",
        border: "none",
        borderBottom: "1px solid #a4a4a4",
        boxSizing: "border-box",
        color: colors.black80,
        fontSize: "15px",
        fontFamily: "Inter",
        "&::before , &::after": {
          borderBottom: "none !important"
        },
        "&$error": {
          color: "#5A5859 !important",
          borderBottom: "1px solid #E72D2D"
        }
      },
      input: {
        marginTop: "4px",
        paddingBottom: "3px"
      }
    },
    MuiInputLabel: {
      root: {
        fontSize: "15px",
        fontFamily: "Inter",
        color: colors.black40,
        "&$focused": {
          color: colors.black40
        },
        "&$shrink": {
          color: colors.black60,
          fontSize: "13px",
          transform: "translate(0, 1.5px) scale(1)"
        },
        "&$error": {
          color: "#ADACAD !important"
        }
      },
      animated: {
        paddingLeft: "10px"
      }
    },
    MuiSnackbar: {
      root: {
        maxWidth: "420px",
        top: "40px !important",
        left: "50%",
        right: "auto",
        transform: "translateX(-50%)"
      }
    },
    MuiList: {
      root: {
        padding: "0px"
      }
    },
    MuiListItemText: {
      root: {
        padding: 0,
        color: colors.black90
      },
      primary: {
        fontFamily: "Inter",
        fontSize: "13px"
      }
    },
    MuiCircularProgress: {
      colorPrimary: {
        color: colors.primaryExtraLight
      }
    }
  }
});
