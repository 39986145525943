import * as React from "react";
import { Icon } from "@material-ui/core";
import { connect } from "react-redux";
import { RouteComponentProps, withRouter } from "react-router";
import { logout } from "pages/Auth/redux/actions";
import ClickAwayListener from "@material-ui/core/ClickAwayListener";
import styles from "./UserBar.module.scss";
import { ReactComponent as LogoutIcon } from "assets/icons2/Logout.svg";
import ActionMenuItem from "components/ActionMenu/ActionMenuItem/ActionMenuItem";
import ActionMenu from "components/ActionMenu/ActionMenu";

interface Props extends RouteComponentProps {
  logout(preventRedirect: boolean);
  name: string;
  email: string;
}

interface State {
  anchorEl: HTMLElement | null;
}

class UserBar extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      anchorEl: null
    };
  }

  handleClick = (event: any) => {
    this.setState({
      anchorEl: event.currentTarget
    });
  };

  handleClose = () => {
    this.setState({
      anchorEl: null
    });
  };

  render() {
    const { name, email, logout } = this.props;
    const { anchorEl } = this.state;

    const menuItems = [
      [
        <ActionMenuItem
          text={"Logout"}
          key="log_out"
          onClick={e => {
            e.stopPropagation();
            this.handleClose();
            logout(false);
          }}
          icon={<LogoutIcon />}
        />
      ]
    ];

    return (
      <ClickAwayListener onClickAway={this.handleClose}>
        <div>
          <div
            className={styles.root}
            onClick={this.handleClick}
            data-testid={"user-bar"}
          >
            <div className={styles.container}>
              <div className={styles.textContainer}>
                <span className={styles.text}>{name || email}</span>
              </div>{" "}
              <div className={styles.iconContainer}>
                {anchorEl ? (
                  <Icon> keyboard_arrow_up </Icon>
                ) : (
                  <Icon> keyboard_arrow_down </Icon>
                )}
              </div>
            </div>
          </div>
          {!!anchorEl && (
            <ActionMenu
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              lists={menuItems}
              open={true}
              anchorEl={anchorEl}
              onClose={this.handleClose}
            />
          )}
        </div>
      </ClickAwayListener>
    );
  }
}

const mapStateToProps = (state: any) => {
  return {};
};

export default connect(mapStateToProps, { logout })(withRouter(UserBar));
