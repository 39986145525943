import { AuthSagas } from "pages/Auth/redux/sagas";
import { ReportsSagas } from "pages/Reports/redux/sagas";
import { PatientsSagas } from "pages/Patients/redux/sagas";
import { all } from "redux-saga/effects";
import { maintainanceSagas } from "./maintainance/sagas";

export default function* rootSaga(): any {
  yield all([
    AuthSagas(),
    ReportsSagas(),
    PatientsSagas(),
    maintainanceSagas(),
  ]);
}
