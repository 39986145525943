import { eventChannel } from "redux-saga";
import { call, put, take } from "redux-saga/effects";
import {
  putStatusMessage,
  StatusMessageType
} from "../../components/StatusMessage/redux/actions";

import SnackLinkMessage from "../../components/SnackLinkMessage";

import { ApplicationRefresh } from "./actions";

function countdown(secs) {
  return eventChannel(emitter => {
    const iv = setInterval(() => {
      emitter(ApplicationRefresh());
    }, secs);
    return () => {
      clearInterval(iv);
    };
  });
}

export const fetchVersion = () => {
  let regex = /<meta id="app-version" name="version" content="(\d+.\d+.\d+-\w+)"/;

  return fetch(`/`, {
    method: "GET",
    redirect: "follow",
    cache: "no-cache"
  })
    .then(response => response.text())
    .then(result => {
      return result.match(regex)?.[1];
    })
    .catch(() => {
      return Promise.resolve(null);
    });
};

export function* maintainanceSagas() {
  const chan = yield call(countdown, 1000 * 30);
  let version = null;

  try {
    while (true) {
      yield take(chan);

      let appVersion = yield call(fetchVersion);

      if (appVersion == null) {
        continue;
      }

      if (version === null) {
        version = appVersion;
      } else if (version !== appVersion) {
        chan.close();
      }
    }
  } finally {
    yield put(
      putStatusMessage(
        SnackLinkMessage(
          `The app has been updated, please `,
          `reload the page`,
          "/"
        ),
        StatusMessageType.Error
      )
    );
  }
}
