import { createAction } from "redux-actions";
import { Report } from "../Report.interface";

export const ReportsActions = {
  LOAD_REPORTS_REQUEST: "MEMBER/LOAD_REPORTS_REQUEST",
  LOAD_REPORTS_SUCCESS: "MEMBER/LOAD_REPORTS_SUCCESS",
  LOAD_REPORTS_FAILURE: "MEMBER/LOAD_REPORTS_FAILURE",
};

export const loadReports = createAction(
  ReportsActions.LOAD_REPORTS_REQUEST,
  () => {}
);
export const loadReportsSuccess = createAction(
  ReportsActions.LOAD_REPORTS_SUCCESS,
  (reports: Report[]) => reports
);
export const loadReportsFailure = createAction(
  ReportsActions.LOAD_REPORTS_FAILURE,
  (errorMessage: string) => errorMessage
);