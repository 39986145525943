import { all, put, takeEvery } from "redux-saga/effects";
import {
  loadDetailsFailure,
  loadDetailsSuccess,
  loadPatientsFailure,
  loadPatientsSuccess,
  PatientsActions,
} from "./actions";
import {
  getHospitalPatients,
  getPatientsDetails,
} from "businessLogic/services/reports";
import { Action } from "redux-actions";

function* loadPatientsSaga() {
  try {
    const data = yield getHospitalPatients();

    yield put(loadPatientsSuccess(data));
  } catch (err) {
    yield put(loadPatientsFailure(err.message || "Error "));
  }
}

function* loadDetailsSaga(action: Action<any>) {
  try {
    const { patientId, page } = action.payload;
    const data = yield getPatientsDetails(patientId, page);
    yield put(loadDetailsSuccess(data, page));
  } catch (err) {
    yield put(loadDetailsFailure(err.message || "Error "));
  }
}

export function* PatientsSagas() {
  return yield all([
    takeEvery(PatientsActions.LOAD_PATIENTS_REQUEST, loadPatientsSaga),
    takeEvery(PatientsActions.LOAD_DETAILS_REQUEST, loadDetailsSaga),
  ]);
}
