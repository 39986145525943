/**
 * this define to which version to migrate the state
 */
export const MIGRATION_VERSION = 2;

export const stateMigrations = {
  1: state => {
    /**
     * State Migration
     * Clear query state
     */
    return {
      ...state,
      query: {}
    };
  },
  2: state => {
    /**
     * State Migration
     * Clear query state
     */
    return {
      ...state,
      query: {}
    };
  }
};
