import React, { ReactElement } from "react";
import { Divider, List, Popover } from "@material-ui/core";
import styles from "./ActionMenu.module.scss";
import uniqueid from "lodash/uniqueId";
import { ActionMenuItemProps } from "./ActionMenuItem/ActionMenuItem";
import { PopoverOrigin } from "@material-ui/core/Popover/Popover";

interface Props {
  lists: ReactElement<ActionMenuItemProps>[][];
  open: boolean;
  anchorEl: HTMLElement | null;
  onClose: () => void;
  anchorOrigin?: PopoverOrigin;
  transformOrigin?: PopoverOrigin;
}

class ActionMenu extends React.Component<Props> {
  public render() {
    const {
      lists,
      open,
      anchorEl,
      onClose,
      anchorOrigin,
      transformOrigin
    } = this.props;

    return (
      <Popover
        open={open}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "right",
          ...anchorOrigin
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "right",
          ...transformOrigin
        }}
        classes={{ paper: styles.paper }}
        anchorEl={anchorEl}
        onClose={onClose}
      >
        <List component="nav" classes={{ root: styles.list }}>
          {lists.flatMap((item, index) => {
            if (index > 0) {
              return [
                <Divider key={uniqueid()} classes={{ root: styles.divider }} />,
                item
              ];
            }

            return item;
          })}
        </List>
      </Popover>
    );
  }
}

export default ActionMenu;
