import { UserDetails } from "../../model";
import * as _ from "lodash";

export const USER_PERMISSIONS = {
  UPLOAD: "core.hospital_editor",
  MANAGE: "core.hospital_admin"
};

function haveUserPermission(user: UserDetails, permission: string) {
  //is admin
  if (_.get(user, "is_superuser", false)) return true;

  //no permission is required
  if (!permission) return true;

  //no user session or user has no permissions
  if (!user || _.isEmpty(user.user_permissions)) return false;

  // @ts-ignore
  return _.indexOf(user.user_permissions, permission) > -1;
}

function canUpload(user: UserDetails) {
  return haveUserPermission(user, USER_PERMISSIONS.UPLOAD);
}

function canManage(user: UserDetails) {
  return haveUserPermission(user, USER_PERMISSIONS.MANAGE);
}

export const Permissions = {
  haveUserPermission,
  canUpload,
  canManage
};
