import React, { Component } from "react";
import ErrorScreen from "components/ErrorScreen/ErrorScreen";

export default class Error403 extends Component {
  render() {
    return (
      <ErrorScreen
        title={"YOU ARE NOT ALLOWED TO ACCESS THIS PAGE"}
        imageUrl={""}
      />
    );
  }
}
