import React, { Component } from "react";
import styles from "./styles.module.scss";

interface LoaderProps {
  btnLoader?: boolean;
}
export default class Loader extends Component<LoaderProps, any> {
  render() {
    const { btnLoader } = this.props;
    return (
      <div
        className={`${styles.ballLoader} ${
          btnLoader ? styles.smallLoader : styles.defaultLoader
        }`}
      >
        <div
          className={`${styles.ballLoaderBall} ${styles.ball1} ${
            btnLoader ? styles.smallBall : styles.defaultBall
          }`}
        ></div>
        <div
          className={`${styles.ballLoaderBall} ${styles.ball2} ${
            btnLoader ? styles.smallBall : styles.defaultBall
          }`}
        ></div>
        <div
          className={`${styles.ballLoaderBall} ${styles.ball3} ${
            btnLoader ? styles.smallBall : styles.defaultBall
          }`}
        ></div>
      </div>
    );
  }
}
