import { all, put, takeEvery } from "redux-saga/effects";
import {
  loadReportsFailure,
  loadReportsSuccess,
  ReportsActions,
} from "./actions";
import { getMedicalReports } from "businessLogic/services/reports";

function* loadReportsSaga() {
  try {
    const data = yield getMedicalReports();

    yield put(loadReportsSuccess(data));
  } catch (err) {
    yield put(loadReportsFailure(err.message || "Error "));
  }
}

export function* ReportsSagas() {
  return yield all([
    takeEvery(ReportsActions.LOAD_REPORTS_REQUEST, loadReportsSaga),
  ]);
}
