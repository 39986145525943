import * as React from "react";

import CircularProgress from "@material-ui/core/CircularProgress";
import styles from "./styles.module.scss";

import classNames from "classnames/bind";

const cx = classNames.bind(styles);

const DEFAULT_SPINNER_SIZE = 50;

export interface SpinnerProps {
  size?: number;
  text?: string;
  className?: string;
  color?: any;
}

export default function Spinner(props: SpinnerProps) {
  return (
    <div className={cx(styles.spinner, props.className)}>
      <CircularProgress
        classes={{ colorPrimary: styles.colorPrimary }}
        size={props.size || DEFAULT_SPINNER_SIZE}
        color={props.color ? props.color : "inherit"}
      />
      {props.text && <span className={styles.text}>{props.text}</span>}
    </div>
  );
}
