import enMessages from "../translations/en.json";
import appLocaleData from "react-intl/locale-data/en";

const EnLang = {
  messages: {
    ...enMessages
  },
  languageId: "english",
  locale: "en-US",
  data: appLocaleData
};
export default EnLang;
