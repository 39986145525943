import { AuthActions } from "./actions";
import { Action, handleActions } from "redux-actions";
import { SessionData, UserDetails, ResetPass } from "businessLogic/model";

interface AuthState {
  errorLoginMessage?: string;
  userDetails?: UserDetails | null;
  session?: SessionData;
  isLoading?: boolean;
  isLogoutLoading?: boolean;
  resetPassEmail?: ResetPass;
  emailSent?: boolean;
  errorResetMessage?: string;
  isPasswordSet?: boolean;
}

export const INITIAL_STATE: AuthState = {};

const reducerMap = {};

reducerMap[AuthActions.LOGIN] = (
  state: AuthState,
  action: Action<void>
): AuthState => {
  return { ...state, errorLoginMessage: undefined, isLoading: true };
};

reducerMap[AuthActions.LOGIN_SUCCESS] = (
  state: AuthState,
  action: Action<UserDetails>
): AuthState => {
  return {
    ...state,
    userDetails: { ...action.payload },
    errorLoginMessage: undefined,
    isLoading: false
  };
};

reducerMap[AuthActions.SET_ERROR_LOGIN] = (
  state: AuthState,
  action: Action<string>
): AuthState => {
  return {
    ...state,
    errorLoginMessage: action.payload,
    isLoading: false
  };
};

reducerMap[AuthActions.SET_ERROR_RESET_PASS] = (
  state: AuthState,
  action: Action<string>
): AuthState => {
  return {
    ...state,
    errorResetMessage: action.payload,
    emailSent: false,
    isLoading: false,
    isPasswordSet: false
  };
};

reducerMap[AuthActions.RESET_PASSWORD] = (
  state: AuthState,
  action: Action<ResetPass>
): AuthState => {
  return {
    ...state,
    errorResetMessage: null,
    resetPassEmail: action.payload,
    emailSent: false,
    isLoading: true
  };
};

reducerMap[AuthActions.EMAIL_SENT] = (
  state: AuthState,
  action: Action<ResetPass>
): AuthState => {
  return {
    ...state,
    errorResetMessage: null,
    emailSent: true,
    isLoading: false
  };
};

reducerMap[AuthActions.SET_NEW_PASSWORD] = (
  state: AuthState,
  action: Action<ResetPass>
): AuthState => {
  return { ...state, resetPassEmail: undefined, isLoading: true };
};
reducerMap[AuthActions.SET_PASSWORD_SUCCESS] = (
  state: AuthState,
  action: Action<ResetPass>
): AuthState => {
  return {
    ...state,
    resetPassEmail: undefined,
    isLoading: false,
    isPasswordSet: true
  };
};

reducerMap[AuthActions.SET_SESSION] = (
  state: AuthState,
  action: Action<SessionData>
): AuthState => {
  const { session } = state;
  const newSession = {
    ...session,
    access_token: action.payload.auth_token
  };
  if (action.payload.refresh_token) {
    newSession["refresh_token"] = action.payload.refresh_token;
  }
  return { ...state, session: newSession };
};

reducerMap[AuthActions.CLEAR_SESSION] = (
  state: AuthState,
  action: Action<any>
): AuthState => {
  return { ...state, session: null };
};

reducerMap[AuthActions.LOGOUT] = (
  state: AuthState,
  action: Action<string>
): AuthState => {
  return { ...state, userDetails: null, isLogoutLoading: true };
};
reducerMap[AuthActions.LOGOUT_SUCCESS] = (
  state: AuthState,
  action: Action<string>
): AuthState => {
  return INITIAL_STATE;
};

reducerMap[AuthActions.LOGOUT_FAILURE] = (
  state: AuthState,
  action: Action<string>
): AuthState => {
  return INITIAL_STATE;
};

export default handleActions<AuthState, any>(reducerMap, INITIAL_STATE);
