import { SupportedLanguage } from ".";

const DEFAULT_LANGUAGE: SupportedLanguage = "english";

export default {
  defaultLanguage: DEFAULT_LANGUAGE,
  options: [
    {
      languageId: "english",
      locale: "en",
      text: "English"
    },
    {
      languageId: "german",
      locale: "de",
      text: "German"
    }
  ]
};
