import { ReportsActions } from "./actions";
import { Action, handleActions } from "redux-actions";
import { AuthActions } from "pages/Auth/redux/actions";

interface ReportsState {
  reports: any[] | null;
  errorMessage: string | null;
  isLoading: boolean;
}

export const INITIAL_STATE: ReportsState = {
  reports: null,
  errorMessage: null,
  isLoading: false
};

const reducerMap = {};

reducerMap[ReportsActions.LOAD_REPORTS_REQUEST] = (
  state: ReportsState,
  action: Action<void>
): ReportsState => {
  return { ...state, errorMessage: null, isLoading: true };
};

reducerMap[ReportsActions.LOAD_REPORTS_SUCCESS] = (
  state: ReportsState,
  action: Action<any[]>
): ReportsState => {
  return { reports: action.payload, errorMessage: null, isLoading: false };
};

reducerMap[ReportsActions.LOAD_REPORTS_FAILURE] = (
  state: ReportsState,
  action: Action<string>
): ReportsState => {
  return { ...state, errorMessage: action.payload, isLoading: false };
};

reducerMap[AuthActions.LOGOUT_SUCCESS] = (
  state: ReportsState,
  action: Action<void>
): ReportsState => {
  return INITIAL_STATE;
};

export default handleActions<ReportsState, any>(reducerMap, INITIAL_STATE);
