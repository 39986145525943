import { createAction } from "redux-actions";
import { SupportedLanguage } from "lang";

export const AppActions = {
  CHANGE_LANGUAGE: "APP/CHANGE_LANGUAGE",
  SAVE_SORT_DETAILS: "SAVE_SORT_DETAILS"
};

export const setLanguage = createAction(
  AppActions.CHANGE_LANGUAGE,
  (language: SupportedLanguage) => language
);
export const saveSortDetails = createAction(
  AppActions.SAVE_SORT_DETAILS,
  (sortDetails: any) => sortDetails
);
