import React from "react";
import {
  resetStatusMessage,
  StatusMessage,
  StatusMessageType
} from "./redux/actions";
import { connect } from "react-redux";
import SnackBarView from "../SnackBar";

const AUTO__HIDE_SUCCESS_MESSAGE_DELAY_MS = 3000;

interface Props {
  statusMessage: StatusMessage | null;
  resetStatusMessage: () => void;
}

const StatusMessageContainer = ({
  statusMessage,
  resetStatusMessage
}: Props) => {
  return (
    <SnackBarView
      vertical={"top"}
      horizontal={"center"}
      openSnackBar={!!statusMessage}
      autoHideDuration={
        statusMessage?.type === StatusMessageType.Success
          ? AUTO__HIDE_SUCCESS_MESSAGE_DELAY_MS
          : null
      }
      closeSnackBar={resetStatusMessage}
      type={statusMessage && statusMessage.type}
      message={statusMessage && statusMessage.message}
    />
  );
};

export default connect(
  (state: any) => {
    return {
      statusMessage: state.statusMessage.statusMessage
    };
  },
  { resetStatusMessage }
)(StatusMessageContainer);
