import React from "react";
import cn from "classnames";
import Tooltip, { TooltipProps } from "@material-ui/core/Tooltip";

import styles from "./styles.module.scss";

function CustomTooltip(props: TooltipProps) {
  return (
    <Tooltip
      arrow
      classes={{
        popper: styles.popper,
        tooltip: cn(styles.tooltip, styles.forced),
        tooltipPlacementLeft: styles.tooltipPlacementLeft,
        tooltipPlacementRight: styles.tooltipPlacementRight,
        tooltipPlacementTop: styles.tooltipPlacementTop,
        tooltipPlacementBottom: styles.tooltipPlacementBottom,
        arrow: cn(styles.arrow, styles.forced)
      }}
      {...props}
      title={<span className={styles.tooltipTitle}>{props.title}</span>}
    />
  );
}
export default CustomTooltip;
