import { createAction } from "redux-actions";
import { Patient, Detail } from "../interfaces";

export const PatientsActions = {
  LOAD_PATIENTS_REQUEST: "PATIENT/LOAD_PATIENTS_REQUEST",
  LOAD_PATIENTS_SUCCESS: "PATIENT/LOAD_PATIENTS_SUCCESS",
  LOAD_PATIENTS_FAILURE: "PATIENT/LOAD_PATIENTS_FAILURE",
  LOAD_DETAILS_REQUEST: "PATIENT/LOAD_DETAILS_REQUEST",
  LOAD_DETAILS_SUCCESS: "PATIENT/LOAD_DETAILS_SUCCESS",
  LOAD_DETAILS_FAILURE: "PATIENT/LOAD_DETAILS_FAILURE"
};

export const loadPatients = createAction(
  PatientsActions.LOAD_PATIENTS_REQUEST,
  () => {}
);
export const loadPatientsSuccess = createAction(
  PatientsActions.LOAD_PATIENTS_SUCCESS,
  (patients: Patient[]) => patients
);
export const loadPatientsFailure = createAction(
  PatientsActions.LOAD_PATIENTS_FAILURE,
  (errorMessage: string) => errorMessage
);

export const loadDetails = createAction(
  PatientsActions.LOAD_DETAILS_REQUEST,
  (patientId, page) => ({ patientId, page })
);
export const loadDetailsSuccess = createAction(
  PatientsActions.LOAD_DETAILS_SUCCESS,
  (details: Detail[], page: string) => ({ details, page })
);
export const loadDetailsFailure = createAction(
  PatientsActions.LOAD_DETAILS_FAILURE,
  (errorMessage: string) => errorMessage
);
