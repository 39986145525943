import { AppActions } from "./actions";
import { Action, handleActions } from "redux-actions";
import { SupportedLanguage } from "lang";

export interface AppState {
  language?: SupportedLanguage;
  sortDetails: any;
}

export const INITIAL_STATE: AppState = {
  language: "english",
  sortDetails: {}
};

const reducerMap = {};

reducerMap[AppActions.CHANGE_LANGUAGE] = (
  state: AppState,
  action: Action<SupportedLanguage>
): AppState => {
  return { ...state, language: action.payload };
};
reducerMap[AppActions.SAVE_SORT_DETAILS] = (
  state: AppState,
  action: Action<any>
): AppState => {
  const { entity, sortDetails } = action.payload;
  return {
    ...state,
    sortDetails: {
      [entity]: sortDetails
    }
  };
};

export default handleActions<AppState, any>(reducerMap, INITIAL_STATE);
