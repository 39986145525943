import React, { ReactElement } from "react";
import styles from "./MenuItem.module.scss";
import CustomTooltip from "components/Tooltip";
import cn from "classnames";
import { Link } from "react-router-dom";

interface Props {
  text?: string;
  icon?: ReactElement;
  selectedIcon?: ReactElement;
  tooltip?: string;
  onClick?: (e?: any) => void;
  selected?: boolean;
  disabled?: boolean;
  to?: string;
}

const MenuItem: React.FunctionComponent<Props> = props => {
  const {
    text,
    icon,
    selectedIcon,
    tooltip,
    onClick,
    selected,
    disabled,
    to
  } = props;

  const wrapByLink = element => {
    if (!to || disabled) {
      return element;
    }

    return <Link to={to}>{element}</Link>;
  };

  const content = wrapByLink(
    <div
      className={cn(styles.listItem, {
        [styles.selected]: selected,
        [styles.disabled]: disabled
      })}
      onClick={!disabled ? onClick : () => {}}
    >
      {icon && <span className={styles.iconContainer}>{icon}</span>}

      <span className={styles.text}>{text}</span>
      <span className={styles.selectIconContainer}>
        {!!selectedIcon && selected && selectedIcon}
      </span>
    </div>
  );

  if (!tooltip) {
    return content;
  }

  return (
    <CustomTooltip title={tooltip} placement={"left"}>
      {content}
    </CustomTooltip>
  );
};

export default MenuItem;
