import React, { ReactElement } from "react";
import styles from "./ErrorScreen.module.scss";
import failureImg from "assets/images/failure.svg";
import cn from "classnames";

export enum ErrorScreenImagePosition {
  Top = "TOP",
  Bottom = "BOTTOM"
}

interface Props {
  title: string;
  description?: string | ReactElement;
  imageUrl: string;
  footer?: any;
  imagePosition: ErrorScreenImagePosition;
}

const ErrorScreen = ({
  title,
  description,
  imageUrl,
  footer,
  imagePosition
}: Props) => {
  const imageContainer = (
    <div className={cn(styles.imageContainer, styles[imagePosition])}>
      <img className={styles.image} src={imageUrl} />
    </div>
  );

  return (
    <div className={styles.wrapper}>
      <div className={styles.root}>
        {imagePosition === ErrorScreenImagePosition.Top && imageContainer}
        <div className={styles.title}>{title}</div>
        {description && <div className={styles.description}>{description}</div>}
        {imagePosition === ErrorScreenImagePosition.Bottom && imageContainer}
        {footer && <div className={styles.footer}>{footer}</div>}
      </div>
    </div>
  );
};

ErrorScreen.defaultProps = {
  imagePosition: ErrorScreenImagePosition.Bottom,
  imageUrl: failureImg
};

export default ErrorScreen;
