import React, { ReactElement } from "react";
import styles from "./ActionMenuItem.module.scss";
import CustomTooltip from "../../Tooltip";
import cn from "classnames";

export interface ActionMenuItemProps {
  text?: string;
  icon?: ReactElement;
  selectedIcon?: ReactElement;
  tooltip?: string;
  onClick?: (e?: any) => void;
  selected?: boolean;
}

const ActionMenuItem = (props: ActionMenuItemProps) => {
  const { text, icon, selectedIcon, tooltip, onClick, selected } = props;

  const content = (
    <div
      className={cn(styles.listItem, { [styles.selected]: selected })}
      onClick={onClick}
    >
      {icon && <span className={styles.iconContainer}>{icon}</span>}

      <span className={styles.text}>{text}</span>
      <span className={styles.selectIconContainer}>
        {!!selectedIcon && selected && selectedIcon}
      </span>
    </div>
  );

  if (!tooltip) {
    return content;
  }

  return (
    <CustomTooltip title={tooltip} placement={"left"}>
      {content}
    </CustomTooltip>
  );
};

export default ActionMenuItem;
