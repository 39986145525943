import * as React from "react";
import * as ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import configureStore, { history } from "redux/configureStore";
import { ConnectedRouter } from "connected-react-router";
import { Route } from "react-router-dom";
import App from "components/App";
import { PersistGate } from "redux-persist/integration/react";
import * as serviceWorker from "./serviceWorker";
import "styles/reset.scss";
import "styles/fonts.scss";
import * as Sentry from "@sentry/browser";
import ErrorBoundary from "components/ErrorBoundary/ErrorBoundary";

const { store, persistor } = configureStore();

if (process.env.NODE_ENV === "production") {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    attachStacktrace: true,
    environment: new URL(process.env.REACT_APP_BASE_URL).host,
    release: `packui@${process.env.REACT_APP_VERSION}-${process.env.REACT_APP_HASH}`,
  });
}
const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <ErrorBoundary>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <ConnectedRouter history={history}>
          <Route component={App} />
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  </ErrorBoundary>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
