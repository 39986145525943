import React, { Component } from "react";
import Spinner from "components/common/Spinner";
import styles from "./styles.module.scss";
import cn from "classnames";

interface Props {
  dimmed?: boolean;
  custom?: string;
}

export default class LoadingOverlap extends Component<Props, any> {
  static defaultProps = {
    dimmed: false,
    custom: "",
  };

  render() {
    const { dimmed, custom } = this.props;

    return (
      <div
        className={cn(custom || styles.overlayWrap, {
          [styles.dimmed]: dimmed,
        })}
      >
        <Spinner size={28} />
      </div>
    );
  }
}
