import React from "react";
import { ReactComponent as PatientHubLogo } from "assets/images/patientHubLogo.svg";
import { ReactComponent as MyGauchLogo } from "assets/images/mygauch.svg";

import styles from "./styles.module.scss";

const Logo = () => (
  <div className={styles.logoHeader}>
    <div className={styles.gaucherLogo}>
      <MyGauchLogo />
    </div>
    <div className={styles.hubLogo}>
      <PatientHubLogo />
    </div>
  </div>
);

export default Logo;
