import React from "react";
import ErrorScreen from "components/ErrorScreen/ErrorScreen";
import { SUPPORT_EMAIL } from "businessLogic/constants";
import buttonStyles from "styles/buttons.module.scss";
import MaterialButton from "components/MaterialButton/MaterialButton";
import { fetchVersion } from "../../redux/maintainance/sagas";
import Loader from "../Loader";

interface IState {
  hasError: boolean;
  chunkError: boolean;
  isNewVersion: boolean;
}

class ErrorBoundary extends React.Component<{}, IState> {
  public static getDerivedStateFromError(error) {
    return {
      hasError: true,
      chunkError: error.name === `ChunkLoadError`,
      isNewVersion: false,
    };
  }

  constructor(props: {}) {
    super(props);
    this.state = {
      hasError: false,
      chunkError: false,
      isNewVersion: false,
    };
  }

  componentDidUpdate() {
    if (this.state.chunkError) {
      fetchVersion().then((version: string) => {
        const currentMetaVersion = (document.getElementById(
          "app-version"
        ) as HTMLMetaElement).content;

        if (version === null) {
          this.setState(() => {
            return {
              hasError: true,
              isNewVersion: false,
              chunkError: false,
            };
          });
          return;
        }

        if (version !== currentMetaVersion) {
          this.setState(() => {
            return {
              hasError: false,
              isNewVersion: true,
              chunkError: false,
            };
          });
        } else {
          this.setState(() => {
            return {
              hasError: true,
              isNewVersion: false,
              chunkError: false,
            };
          });
        }
      });
    }
  }

  public render() {
    if (this.state.chunkError) {
      return <Loader />;
    }

    if (this.state.isNewVersion) {
      return (
        <ErrorScreen
          title={"The app has been updated"}
          description={<>Please reload the page</>}
          footer={
            <div>
              <MaterialButton
                onClick={() => window.location.reload()}
                text={"Reload page"}
              />
            </div>
          }
        />
      );
    }

    if (this.state.hasError) {
      return (
        <ErrorScreen
          title={"Something went wrong"}
          description={
            <>
              Please contact us to resolve the problem{" "}
              <a
                href={`mailto:${SUPPORT_EMAIL}`}
                className={buttonStyles.btn_link}
              >
                {SUPPORT_EMAIL}
              </a>
            </>
          }
          footer={
            <div>
              <MaterialButton
                onClick={() => window.location.reload()}
                text={"Reload page"}
              />
            </div>
          }
        />
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
