import React from "react";
import Header from "components/Header";
import MainSidebar from "./Sidebar/MainSidebar";
import styles from "./styles.module.scss";

const MainPage = ({ Component }) => {
  return (
    <>
      <MainSidebar />
      <div className={styles.content}>
        <Component />
      </div>
    </>
  );
};
export default MainPage;
