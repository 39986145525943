import { createAction } from "redux-actions";
import { ReactNode } from "react";

export enum StatusMessageType {
  Success = "success",
  Error = "error"
}

export interface StatusMessage {
  message: string | ReactNode;
  type: StatusMessageType;
}

export const StatusMessageActions = {
  PUT_MESSAGE: "STATUS_MESSAGE/PUT_MESSAGE",
  RESET: "STATUS_MESSAGE/RESET"
};

export const putStatusMessage = createAction(
  StatusMessageActions.PUT_MESSAGE,
  (message: string | ReactNode, type: StatusMessageType) => ({ message, type })
);

export const resetStatusMessage = createAction(
  StatusMessageActions.RESET,
  () => {}
);
