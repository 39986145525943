import React from "react";
import styles from "./styles.module.scss";

export default (message, link, href = "/") => {
  return (
    <span className={styles.messageWrap}>
      <span>
        {message} <a href={href}>{link}</a>
      </span>
    </span>
  );
};
